<template>
    <div class="w-full lg:w-5/6 xl:w-3/4 2xl:w-1/2 container mx-auto flex flex-col py-6 px-3">
        <h2 class="text-3xl text-primary md:text-4xl font-medium mb-2">
            Persoonlijk tegoed
        </h2>
        <p v-if="showContent && $store.state.credit.totalRemaining > 0" v-html="config.credit.description[lang]">
        </p>
        <div v-if="showContent" class="w-full sm:w-80 px-4 py-2 my-4 bg-gray border border-gray-300 rounded-lg bg-primary">
            <div>
                <p class="text-3xl md:text-4xl font-semibold text-center text-white">
                    € {{ $store.state.credit.totalRemaining | currency }}
                </p>
                <p class="text-lg text-center text-white">tegoed</p>
            </div>
        </div>
        <table v-if="showContent && $store.state.credit.vouchers.length > 0" class="table-auto">
            <thead>
                <tr>
                    <th class="border-b px-1 sm:px-8 py-2 align-top text-left hidden sm:table-cell">
                        Soort
                    </th>
                    <th class="border-b px-1 sm:px-8 py-2 align-top text-left">
                        Code
                    </th>
                    <th class="border-b px-1 sm:px-8 py-2 align-top text-left">
                        Geldig tot
                    </th>
                    <th class="border-b px-1 sm:px-8 py-2 align-top text-right">
                        Saldo
                    </th>            
                    <th class="border-b px-1 sm:px-8 py-2 align-top text-right">
                        &nbsp;
                    </th>
                </tr>
            </thead>
            <tbody class="" v-for="voucher of $store.state.credit.vouchers" v-bind:key="voucher.id">
                <tr class="">
                    <td class="px-1 sm:px-8 py-2 align-top hidden sm:table-cell">
                        <h4 class="">
                            {{ voucher.name }}&nbsp;
                        </h4>
                    </td>
                    <td class="px-1 sm:px-8 py-2 align-top">
                        <CopyText class="mt-1" :text="voucher.code" v-if="showCode(voucher)"/>
                        <span v-else-if="voucher.expired">
                            <i>
                                niet meer beschikbaar
                            </i>
                        </span>
                        <span v-else>
                            <i>
                                nog niet beschikbaar
                            </i>
                        </span>
                    </td>
                    <td class="px-1 sm:px-8 py-2 align-top">
                        <span v-if="voucher.expiryts && voucher.expired">
                            vervallen op {{ voucher.expiryts | moment }} 
                        </span>                        
                        <span v-else-if="voucher.expiryts">
                            geldig tot {{ voucher.expiryts | moment }} 
                        </span>
                        <span v-else>
                            onbeperkt
                        </span>
                    </td>
                    <td class="px-1 sm:px-8 py-2 align-top text-right">
                        <span class="opacity-50">€</span>
                        {{ saldo(voucher) | currency }}
                    </td>
                    <td class="px-4 sm:px-8 py-2 align-top text-right">
                        <div class="hidden sm:table-cell" v-if="mayDonate(voucher)">
                            <button 
                                @click="initDonate(voucher)" 
                                class="py-1 px-2 capitalize tracking-wide bg-primary hover:bg-primary-light text-white font-small rounded
                                    transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                                Doneren
                            </button>
                        </div>
                        <div class="table-cell sm:hidden" v-if="mayDonate(voucher)">
                            <a target="_blank" title="Doneren" @click="initDonate(voucher)">
                                <font-awesome-icon icon="envelope" size="1x" class="block m-auto mt-1 text-primary rounded hover:bg-gray-200
                                    transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"/>
                            </a>
                        </div>
                        <div class="hidden sm:table-cell pl-2" v-if="mayRefund(voucher)">
                            <button 
                                @click="initRefund(voucher)" 
                                class="py-1 px-2 capitalize tracking-wide bg-primary hover:bg-primary-light text-white font-small rounded
                                    transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                                Uitbetalen
                            </button>
                        </div>
                        <div class="table-cell sm:hidden pl-2" v-if="mayRefund(voucher)">
                            <a target="_blank" title="Uitbetalen" @click="initRefund(voucher)">
                                <font-awesome-icon icon="exchange-alt" size="1x" class="block m-auto mt-1 text-primary rounded hover:bg-gray-200
                                    transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"/>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-if="isLoading">
            <font-awesome-icon icon="spinner" spin="spin" size="3x" class="text-primary block m-auto"/>
        </div>
        <div v-if="error">
            <p>
                Er ging iets mis.
            </p>
            <p>
                Probeer het opnieuw of neem contact met ons op via <a :href="`mailto:${ config.email }`">{{ config.email }}</a>.
            </p>
        </div>

        <Modal 
            button="Sluiten" 
            title="Doneren" 
            icon="spinner"
            ref="donateLoadingModal">
        </Modal>

        <Modal
            title="Neem contact op met de kassa"
            ref="donateNotPossible">
            <p class="text-lg">
                Het is niet mogelijk deze tegoedbon te doneren.
                Neem contact op met de kassa via <a class="underline" :href="`mailto:${config.email}`" target="_blank">{{ config.email }}</a> of {{ config.phone }}.
            </p>
            <p class="text-lg" v-if="config.website">
                Check de openingstijden van de kassa via <a class="underline" target="_blank" :href="config.website">{{ config.website }}</a>.
            </p>
            <ul>
                <li v-if="config.email">E-mail: <a class="underline" :href="`mailto:${config.email}`" target="_blank">{{ config.email }}</a></li>
                <li v-if="config.phone">Telefoon: {{ config.phone }}</li>
            </ul>
        </Modal>

        <ConfirmationModal 
            @execute="executeDonate" 
            @cancel="clearDonate" 
            title="Doneren"
            confirmation="Ik weet zeker dat ik deze tegoedbon wil doneren."
            ref="donateConfirmModal"
            >
            <p class="my-1 sm:my-4 text-md sm:text-xl leading-relaxed">
                Doneer hier je tegoedbon <b>{{ donateState.name }}</b> 
                met saldo <span class="opacity-50">€</span>
                {{ donateState.saldo | currency }}.
            </p>
        </ConfirmationModal>

        <Modal
            @closed="refreshCredit" 
            title="Het is gelukt" 
            icon="check-circle"
            ref="donateFinishedModal"
        >
            <p class="text-lg">
                Je hebt <b>&euro; {{ donateResult.donationAmount | currency }}</b> gedoneerd. Hartelijk dank!
            </p>            
        </Modal>        
        
        <Modal 
            button="Sluiten" 
            title="Uitbetalen" 
            icon="spinner"
            ref="refundLoadingModal">
        </Modal>

        <Modal
            title="Neem contact op met de kassa"
            ref="refundNotPossible">
            <p class="text-lg">
                Omdat je tegoedbon niet (volledig) online is betaald, kunnen we je aanvraag niet direct afhandelen. 
                Neem contact op met de kassa via <a class="underline" :href="`mailto:${config.email}`" target="_blank">{{ config.email }}</a> of {{ config.phone }}.
            </p>
            <p class="text-lg" v-if="config.website">
                Check de openingstijden van de kassa via <a class="underline" target="_blank" :href="config.website">{{ config.website }}</a>.
            </p>
            <ul>
                <li v-if="config.email">E-mail: <a class="underline" :href="`mailto:${config.email}`" target="_blank">{{ config.email }}</a></li>
                <li v-if="config.phone">Telefoon: {{ config.phone }}</li>
            </ul>
        </Modal>

        <ConfirmationModal 
            @execute="executeRefund" 
            @cancel="clearRefund" 
            title="Uitbetalen"
            confirmation="Ik weet zeker dat ik deze tegoedbon wil laten uitbetalen."
            ref="refundConfirmModal"
            >
            <p class="my-1 sm:my-4 text-md sm:text-xl leading-relaxed">
                Wissel hier je tegoedbon <b>{{ refundState.name }}</b> 
                met saldo <span class="opacity-50">€</span>
                {{ refundState.saldo | currency }} in, en ontvang je geld terug.
            </p>
        </ConfirmationModal>

        <Modal
            @closed="refreshCredit" 
            title="Het is gelukt" 
            icon="check-circle"
            ref="refundFinishedModal"
        >
            <p class="text-lg">
                Er wordt <b>&euro; {{ refundResult.refundAmount | currency }}</b> teruggeboekt naar je bankrekening.
            </p>            
        </Modal>

        <Modal
            @closed="refreshCredit"
            title="Niet gelukt"
            ref="errorModal"
            icon="exclamation-circle"
        >
            <p class="text-lg">
                Het is niet gelukt om deze aanvraag te verwerken.
            </p>
            <p class="text-lg">
                Neem voor vragen contact op met <a :href="`mailto:${ config.email }`">{{ config.email }}</a>.
            </p>
        </Modal>             
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CopyText from '../components/CopyText.vue'
import { donateVoucherCheck, donateVoucherExecute, refundVoucherCheck, refundVoucherExecute } from '../services/OrderService'
import Modal from '../components/Modal.vue'
import ConfirmationModal from '../components/ConfirmationModal.vue'
const moment = require("moment")
export default {
  name: 'Ticketmatic',
  title: 'Persoonlijk Tegoed',
  components: {
        CopyText,
        ConfirmationModal,
        Modal,
  },
  data() {
    return {
        isLoading: false,
        error: false,
        donateState: {
            voucherid: null,
            vouchercodeid: null,
            orderid: null,
            name: '',
            saldo: 0,
        },
        donateResult: {
            donable: false,
        },
        refundState: {
            voucherid: null,
            vouchercodeid: null,
            orderid: null,
            name: '',
            saldo: 0,
        },
        refundResult: {
            refundAmount: 0,
        },
    }
  },
  computed: {
      ...mapGetters(["config", "credit"]),
      showContent: function() {
          return this.isLoading === false && !this.error
      }
  },
  methods: {
    showCode(voucher) {
      return (voucher.code && !voucher.expired)
    },
    mayDonate(voucher) {
        return voucher.donable && this.saldo(voucher) > 0;
    },
    mayRefund(voucher) {
        return voucher.refundable;
    },  
    clearDonate() {
        this.donateState = {
            voucherid: null,
            vouchercodeid: null,
            orderid: null,
            name: '',
            saldo: 0,
        }
        this.donateResult = {
            donable: false,
        }
    },  
    clearRefund() {
        this.refundState = {
            voucherid: null,
            vouchercodeid: null,
            orderid: null,
            name: '',
            saldo: 0,
        }
        this.refundResult = {
            refundAmount: 0,
        }
    },
    initDonate: async function (voucher) {
        const saldo = this.saldo(voucher)
        this.donateState = {
            voucherid: voucher.voucherid,
            vouchercodeid: voucher.id,
            orderid: voucher.orderid,
            name: voucher.name,
            saldo,
        };
        this.donateResult = {
        };
        await this.checkDonate(voucher.voucherid, voucher.id, voucher.orderid);
    },
    initRefund: async function (voucher) {
        const saldo = this.saldo(voucher)
          this.refundState = {
              voucherid: voucher.voucherid,
              vouchercodeid: voucher.id,
              orderid: voucher.orderid,
              name: voucher.name,
              saldo,
          };
          this.refundResult = {
          };
          await this.checkRefund(voucher.voucherid, voucher.id, voucher.orderid);
    },
    checkDonate: async function (voucherid, vouchercodeid, orderid) {
        try {
            const result = await donateVoucherCheck(voucherid, vouchercodeid, orderid);
            this.donateResult = result;
            console.log(this.donateResult, result);
            if (this.donateResult.donable) {
                this.$refs.donateConfirmModal.toggle();
            }
            else if(this.donateResult) {
                this.$refs.donateNotPossible.toggle();
            }            
        }
        catch(err) {
            this.$refs.errorModal.toggle();
        }            
    },
    checkRefund: async function (voucherid, vouchercodeid, orderid) {
        try {
            const result = await refundVoucherCheck(voucherid, vouchercodeid, orderid);
            this.refundResult = result;
            console.log(this.refundResult, result);
            if (this.refundResult.refundable) {
                this.$refs.refundConfirmModal.toggle();
            }
            else if(this.refundResult) {
                this.$refs.refundNotPossible.toggle();
            }            
        }
        catch(err) {
            this.$refs.errorModal.toggle();
        }            
    },
    executeDonate: async function () {
        try {
            this.$refs.donateLoadingModal.toggle();
            const { voucherid, vouchercodeid, orderid } = this.donateState;
            const result = await donateVoucherExecute(voucherid, vouchercodeid, orderid);
            this.donateResult = result;
            this.$refs.donateLoadingModal.toggle();
            this.$refs.donateFinishedModal.toggle();
        }
        catch (err) {
            this.$refs.donateLoadingModal.toggle();
            this.$refs.errorModal.toggle();            
        }
    },
    executeRefund: async function () {
        try {
            this.$refs.refundLoadingModal.toggle();
            const { voucherid, vouchercodeid, orderid } = this.refundState;
            const result = await refundVoucherExecute(voucherid, vouchercodeid, orderid);
            this.refundResult = result;
            this.$refs.refundLoadingModal.toggle();
            this.$refs.refundFinishedModal.toggle();
        }
        catch (err) {
            this.$refs.refundLoadingModal.toggle();
            this.$refs.errorModal.toggle();            
        }
    },
    refreshCredit: async function () {
        this.clearRefund();
        await this.$store.dispatch("setCredit");
    },
    saldo: function(voucher) {
        return voucher.amount - voucher.amountused;
    },
  },
  filters: {
    moment: function (date) {
        return moment(date).format('D-M-YYYY');
    }
  },
  async mounted () {
    this.$mixpanel.track('pageview', {
        distinct_id: this.$auth.user,
        account: this.config.shortname,
        page: "Credit",
    })

    if (this.$store.state.credit && this.$store.state.credit.vouchers.length > 0) {
        return; // do not refresh every mount
    }

    this.isLoading = true;
    try {
        await this.$store.dispatch('setCredit')
    } catch (ex) {
        this.error = true
    } finally {
        this.isLoading = false;
    }
  },
}
</script>