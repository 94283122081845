<template>
    <div>
        <div v-if="show" class="min-w-60 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
            <div class="relative w-auto my-6 mx-auto max-w-6xl">
                <!--content-->
                <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-3xl font-semibold">
                        {{ title }}
                    </h3>
                    <button 
                        v-on:click="close"
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                        <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                    </button>
                </div>
                <!--body-->
                <div class="relative p-6 py-2 flex-auto">
                    <div v-if="icon" class="items-center">    
                        <font-awesome-icon :icon="icon" size="3x" class="text-primary block m-auto"></font-awesome-icon>
                    </div>                    
                    <p class="my-4 text-lg leading-relaxed">
                        <slot></slot>
                        <div class="flex flex-col">
                            <label for="confirmation" class="mt-3 inline-flex items-center cursor-pointer">
                                <span class="tm-checkbox relative transition-transform duration-300 ease-in-out">
                                    <span class="block w-14 h-8 border border-grey rounded-full shadow-inner"></span>
                                    <span 
                                        v-bind:class="{ checked: confirmed, unchecked: !confirmed }" 
                                        class="absolute block w-6 h-6 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                                        <input id="confirmation" type="checkbox" class="absolute opacity-0 w-0 h-0" v-model="confirmed"/>
                                    </span>
                                </span>
                                <label for="confirmation" class="ml-3 text-lg sm:text-lg">
                                    {{ confirmation }}
                                </label>
                            </label>
                        </div>                          
                </div>
                <!--footer-->
                <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button 
                        class="text-primary background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
                        v-on:click="close">
                        Sluiten
                    </button>
                    <button type="button" 
                        :disabled="!confirmed"
                        class="text-primary bg-transparent border border-solid disabled:border-gray-400 border-primary hover:bg-primary hover:text-white active:bg-primary disabled:text-gray-400 disabled:border-gray-400 disabled:bg-white font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        v-on:click="confirm"
                        v-html="button">
                    </button>
                </div>
                </div>
            </div>
        </div>
        <div v-if="show" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
</template>

<script>
export default {
  data() {
    return {
        confirmed: false,
        show: false,
    }
  },
  props: {
      title: String,
      confirmation: String,
      icon: String,
      button: {
          type: String,
          default: "Bevestigen",
      },
  },
  methods: {
      toggle: function() {
          this.show = !this.show;
      },
      close: function() {
          this.confirmed = false;
          this.show = false;
          this.$emit('cancel');
      },
      confirm: function() {
          this.confirmed = false;
          this.show = false;
          this.$emit('execute');
      },
  }
}
</script>

<style lang="scss">

// Radio buttons

input[type="radio"] + label span {
    transition: background .2s,
      transform .2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
} 

input[type="radio"]:checked + label span {
  @apply bg-primary;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label{
   @apply text-primary;
}

// Checkbox

span.tm-checkbox:hover {
    transform: scale(1.2);
}

.unchecked {
    @apply bg-inactive;
}

.checked {
    @apply bg-primary transform translate-x-full;
}
</style>